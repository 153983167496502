import axios from 'axios'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/customproduct`

const loadProducts = (filter, picksFilter, page, sort) => {
  return axios.post(api + '/loadProducts', {
    filter: { ...filter, ...picksFilter },
    page: page,
    sort: sort
  })
}
const getProductBySKU = (args) => {
  return axios.post(api + '/getProductBySKU', args)
}
const saveProduct = (product) => {
  return axios.post(api, { product: { ...product } })
}
const updateProduct = (product) => {
  return axios.put(api, { product: { ...product } })
}
const setProductStatus = (product) => {
  return axios.post(api + '/setStatus', { product: { ...product } })
}
const getImageDataUrl = (url) => {
  return axios.post(api + '/imageDataUrl', {
    url: url
  })
}
const loadAllProducts = () => {
  return axios.get(api + '/loadAllProducts', {})
}
const loadProductSales = () => {
  return axios.get(api + '/loadProductSales')
}
const loadCustomerSales = () => {
  return axios.get(api + '/loadCustomerSales')
}
const getArtists = () => {
  return axios.get(api + '/artists', {})
}
const loadArtistProducts = (artist) => {
  return axios.post(api + '/artistProducts', artist)
}
const loadArchivedProducts = () => {
  return axios.get(api + '/archivedProducts')
}
const loadProductsForXero = (args) => {
  return axios.post(api + '/loadProductsForXero', args)
}
const getCalendarPDF = (image) => {
  return axios.post(api + '/getCalendarPdf', { image }, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Calendar.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const getGreetingCardPDF = (image) => {
  return axios.post(api + '/getGreetingCardPDF', { image }, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Card.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const getCatalogPDF = (products) => {
  return axios.post(api + '/getCatalogPDF', { products }, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'Doricmor_Catalogue.pdf'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

export default {
  getImageDataUrl,
  getArtists,
  getProductBySKU,
  saveProduct,
  updateProduct,
  setProductStatus,
  loadArtistProducts,
  loadArchivedProducts,
  loadProducts,
  loadAllProducts,
  loadProductSales,
  loadCustomerSales,
  loadProductsForXero,
  getCalendarPDF,
  getCatalogPDF,
  getGreetingCardPDF
}
